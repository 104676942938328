/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import styled from "styled-components";
import { getTotalLeaks } from "../../DAO/assetSummary";
import { getActiveDMACount, getActiveDMAs } from "../../helper/helper";
import { DMA } from "../../model/DMA";
import { ReactComponent as HomeIcon } from "../../components/Icons/Icon_home.svg";
import "./BreadCrumb.css";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const Main = styled.div`
    width: 100%;
    display: inline-flex;
    margin: 20px 0 0 30px;
`
const StyledHome = styled(HomeIcon)`
  margin-top:0 px;
  }
`
const getDescriptionText = (dmas: DMA[]) => {
    let text: string = "";
    let dmaNames = dmas.map(dma => dma.name)

    if (dmaNames.length === 1) {
        return dmaNames[0];
    }
    dmaNames.sort(function(a, b){
        if(a < b) { return -1; }
        if(a > b) { return 1; }
        return 0;
    })
    let popped = dmaNames.pop();
    dmaNames.length === 1 ? text += dmaNames[0] + " & " + popped : text += dmaNames.join(", ") + " & " + popped;
    return text;
}

export default function SunburstDescription(props: any) {
    let data = props.data;
    let dmas = props.dmas;
    let thisLevel = props.path.reverse();
    if (data === undefined || dmas.length === 0 || dmas.length > 6) {
        return null;
    } else {
        return (
            <Main>
                <div>
                  <span>You are reviewing&nbsp;
                  {
                    getActiveDMACount(dmas) > 0
                    ? getDescriptionText(dmas)
                    : null
                  }
                  </span>
                  <br/>
                  <span> assets with a total of <b>{getTotalLeaks(data, getActiveDMAs(dmas))} leaks</b></span>
                </div>
                <nav style={{marginLeft: 40, marginTop: '-25px'}} aria-label="breadcrumb">
                    <ol style={{padding:'.70rem 1.1rem'}} className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="#" role="button">
                            <StyledHome
                                onClick={() =>
                                    props.onClick("")
                                }/>
                          </a></li>
                        {thisLevel.map((item: any, index: any) => {
                           return index === thisLevel.length-1 ? <li className="breadcrumb-item"><a>{item}</a></li> : 
                          <li className="breadcrumb-item"><a href="#" onClick={() =>props.onClick(item, props.path)}>{item}</a></li>
                        })}
                    </ol>
                </nav>
                
            </Main>
        )
    };
}