import React from 'react';
import { ReactComponent as UpArrow } from '../Icons/arrow-up.svg';
import { ReactComponent as DownArrow } from '../Icons/arrow-down.svg';
import styled from 'styled-components';
import '../../index.css';
 
interface PercentageChangeProps {
    value: any,
    title: String,
    type: String,
    isCompact?: boolean
}
 
const RedContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #B4432F;
    svg path {
        fill: #B4432F;
    }
`

const MintContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #18A2A2;
    svg path {
        fill: #18A2A2
    }
`

const GreenContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #648001;
    svg path {
        fill: #648001
    }
`

const Value = styled.div`
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 2px;
`
 
const Subtitle = styled.div`
    color: #43575A;
    font-family: "Nunito Sans";
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 18px;
    text-align: center;
`
 
export default function PercentageChange(props: PercentageChangeProps) {
    let arrow: any;
    let isRed: boolean;
 
    if (parseFloat(props.value) > 0) {
        isRed = false;
        arrow = <UpArrow style={{height:'18px', width:'16.65px'}}/>
    } else {
        isRed = true;
        arrow = <DownArrow style={{height:'18px', width:'16.65px'}}/>
    }
 
    if (props.type.includes('NRW')) {
        isRed = !isRed;
    }

    const formatValue = (value: number) => {
        return Math.abs(parseFloat(props.value)).toFixed(1);
    }

    const renderColouredContainer = () => {
        let value = formatValue(props.value);

        if (props.type.includes('NRW') && isRed) {
            return <RedContainer>{arrow}<Value>{value}%</Value></RedContainer>
        } else if (props.type.includes('NRW')) {
            return <GreenContainer>{arrow}<Value>{value}%</Value></GreenContainer>
        } else {
            return <MintContainer>{arrow}<Value>{value}%</Value></MintContainer>
        }
    }
 
    return props.isCompact
        ?  (
            <div style={{display:"flex", alignItems:"center", justifyContent: "center"}}>
                {renderColouredContainer()}
                <Subtitle style={{paddingLeft:"5px"}}>VS {props.title}</Subtitle>
            </div>
         )
        : (
            <div>
                {renderColouredContainer()}
                <Subtitle>VS {props.title}</Subtitle>
            </div>
        )
}