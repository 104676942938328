export const colorScheme = {
    five: [
        //Blue
        "#213D58", "#40B3DB", "#9EDAE2",
        //Green
        "#477A20", "#6CA241"
    ],
    ten: [
        //Blue
        "#213D58", "#1F83A6", "#40B3DB", "#9EDAE2", 
        //Green
        "#477A20", "#6CA241", "#AFCE82", 
        //Moss
        "#647F68", "#84A389", "#A6BFAA"
    ],
    twenty: [
        //Blue
        "#213D58", "#1F83A6", "#40B3DB", "#9EDAE2", 
        //Green
        "#477A20", "#6CA241", "#83B859", "#AFCE82", 
        //Moss
        "#647F68", "#84A389", "#A6BFAA", "#DCE9DE",
        //Mint
        "#086A69", "#0E8C8B", "#46C6C6", "#8DEDEB",
        //Purple
        "#8130A4", "#964BB7", "#A56BBE", "#C89DDB"
    ],
    thirty: [
        //Blue
        "#213D58", "#1F83A6", "#40B3DB", "#9EDAE2", 
        //Green
        "#477A20", "#6CA241", "#83B859", "#AFCE82", 
        //Moss
        "#647F68", "#84A389", "#A6BFAA", "#DCE9DE",
        //Mint
        "#086A69", "#0E8C8B", "#46C6C6", "#8DEDEB",
        //Purple
        "#8130A4", "#964BB7", "#A56BBE", "#C89DDB",
        //Orange
        "#A1460F", "#BE581A", "#F77F37", "#FEB588",
        //Magenta
        "#851F4A", "#AD3769", "#E5498B", "#FF93C1",
        //Yellow
        "#96740B", "#F9C83A"
    ],
    sixty: [
        //Blue
        "#213D58", "#136B8A", "#1F83A6", "#309EC4", "#40B3DB", "#5BC2E6", "#77CDEB", "#9EDAE2", "#CFEDF1",
        //Green
        "#366412", "#477A20", "#57902A", "#6CA241", "#83B859", "#AFCE82", "#DAECC1",
        //Moss
        "#546857", "#647F68", "#84A389", "#A6BFAA", "#BFD5C2", "#DCE9DE",
        //Mint
        "#086A69", "#0E8C8B", "#30B0B1", "#46C6C6", "#5DDEDC", "#8DEDEB",
        //Purple
        "#4E0E6A", "#8130A4", "#964BB7", "#A56BBE", "#C89DDB", "#D9B8E9",
        //Orange
        "#A1460F", "#BE581A", "#E0691E", "#F77F37", "#FEB588", "#FFD8BF",
        //Magenta
        "#851F4A", "#AD3769", "#E5498B", "#FF6BAA", "#FF93C1",
        //Yellow
        "#7D5E04", "#96740B", "#C49818", "#F9C83A", "#FFD55C", "#FFE69D",
        //Pink
        "#CD6D5C", "#E3796D", "#FF8E81", "#FFB3AB", "#FFD0CB",
        //Leaf
        "#4B6000", "#648001", "#799900", "#90B30D"
    ]
  }