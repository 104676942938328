const colors: string[] = [
  '#213D58','#1C7B97','#40B3DB','#799900','#366412','#9EDAE2','#18A2A2','#AFCE82','#097E7D','#A6BFAA',
    '#78987D', '#5DDEDC'
];

export const initialDashboardData: any = {
  dmaSummaryConfluenceUrl: "https://watercarestp.atlassian.net/wiki/spaces/TDL/pages/916324438/Te+Puna+Wai+DMA+Summary+Dashboard",
  changeDMAList: {},  
  _dmaCodes: [],
  _consumption: {
    data: [],
    layout: {  
      dragmode: "pan",
      annotations: [
        {
          xref: "paper",
          yref: "paper",
          x: 0,
          xanchor: 'right',
          y: 1,
          yanchor: 'bottom',
          text: '<b>ML/d</b>',
          font: {
            color: "#213D58"
          },
          showarrow: false
        }
      ],
      showlegend: true,
      legend: { 
        orientation: "h", 
        traceorder: "normal",
        xanchor: "center", 
        yanchor: "bottom", 
        x: 0.5,
        y: -0.4
      },
      title: { 
        text: "Total Consumption", 
        x: 0.03, 
        font: {
          size: 24, 
          color: '#213D58'
        }
      },
      autosize: true,
      font: { family: 'Nunito Sans'},
      barmode: "stack",
      xaxis: { 
        type: "date",
        domain: [0.02, 1],        
        dtick: "M1",
        tickformat: "%b\n%Y",
        tickmode: 'linear',
        tickfont:{
          family: "Nunito Sans",
          size: 12,
          color: '#213D58'
        },
        fixedrange: false
      },
      yaxis: { 
        anchor: "free",
        position: 0,
        title: {
          text:"<b>CONSUMPTION</b>",
          font: {
            size: 14,
            color: '#213D58',
            family: "Nunito Sans"
          },          
        },
        fixedrange: true
      },
      yaxis2: {
        rangemode: "tozero",
        overlaying: "y",
        side: "right",
        showgrid: false,
        title: {
          text: "<b>CONNECTIONS</b>",
          font: {
            size: 14,
            color: '#213D58',
            family: "Nunito Sans"
          },
          standoff: 15
        },
        fixedrange: true
      },
      hoverlabel: {
        bgcolor: "#rgb(9, 18, 26)",
        bordercolor: "#rgb(9, 18, 26)",
        font: {
          size: 15,
          color: "white",
          family: "Nunito Sans",
        },
      },
      hovermode: "closest"
    },
    config: { displayModeBar: false },
    style: { width: "100%", height: "auto" },
    useResizeHandler: true,
  },

  _nonRevenueWater: {
    data: [],
    layout: {
      dragmode: "pan",
      annotations: [
        {
          xref: "paper",
          yref: "paper",
          x: 0,
          xanchor: 'right',
          y: 1,
          yanchor: 'bottom',
          text: '<b>ML/d</b>',
          font: {
            color: "#213D58"
          },
          showarrow: false
        }
      ],
      showlegend: true,
      legend: {
        orientation: "h",
        traceorder: "normal",
        xanchor: "center",
        yanchor: "bottom",
        x: 0.5,
        y: -0.4,
      },
      title: { 
        text: "Monthly Non Revenue Water", 
        x: 0.03, 
        font: {
          size: 24, 
          color: '#213D58'
        }
      },
      autosize: true,
      font: { family: "Nunito Sans"},
      colorway: colors,
      barmode: "relative",
      yaxis: {
        anchor: "free",
        position: 0,
        fixedrange: true
      },
      xaxis: {
        type: "date",    
        domain: [0.02, 1],    
        dtick: "M1",
        tickformat: "%b\n%Y",
        tickmode: 'linear',
        tickfont:{
          family: "Nunito Sans",
          size: 12,
          color: '#213D58'
        },
        fixedrange: false
      },
      hovermode: "closest",
      hoverlabel: {
        bgcolor: "#rgb(9, 18, 26)",
        bordercolor: "#rgb(9, 18, 26)",
        font: {
          size: 15,
          color: "white",
          family: "Nunito Sans",
        },
      },
    },
    config: { displayModeBar: false },
    style: { width: "100%", height: "auto" },
    useResizeHandler: true,
  },
  
  _supply: {
    data: [],
    layout: {
      itemdoubleclick: "toggleothers",
      dragmode: "pan",
      annotations: [
        {
          xref: "paper",
          yref: "paper",
          x: 0,
          xanchor: 'right',
          y: 1,
          yanchor: 'bottom',
          text: '<b>ML/d</b>',
          font: {
            color: "#213D58"
          },
          showarrow: false
        }
      ],
      showlegend: true,
      legend: {
        orientation: "h",
        traceorder: "normal",
        yanchor: "bottom",
        xanchor: "center",
        x: 0.5,
        y: -0.4
      },
      title: { 
        text: "Total Supply",
        x: 0.03, 
        font: {
          size: 24, 
          color: '#213D58'
        }
      },
      autosize: true,
      font: { family: "Nunito Sans" },
      hoverlabel: {
        bgcolor: "#rgb(9,18,26)",
        bordercolor: "#rgb(9, 18, 26)",
        font: {
          size: 15,
          color: "white",
          family: "Nunito Sans",
        },
      },
      barmode: "stack",
      yaxis: { 
        anchor: "free",
        position: 0,
        fixedrange: true
      },
      xaxis: {
        type: "date",
        domain: [0.02, 1],        
        dtick: "M1",
        tickformat: "%b\n%Y",
        tickmode: 'linear',
        tickfont:{
          family: "Nunito Sans",
          size: 12,
          color: '#213D58'
        },
        fixedrange: false
      },
      hovermode: "closest",
      colorway: colors,
    },
    config: { displayModeBar: false },
    style: { width: "100%", height: "auto" },
    useResizeHandler: true,
  },

  _kpis : {
    supply: {},
    consumption: {},
    nonRevenueWaterPercent: {},
    nonRevenueWaterAbsolute: {},
    connections: {}
  },

  _error : {
    layout: {
      xaxis: {
        visible: true
      },
      yaxis: {
        visible: true
      },
      annotations: [
        {
            text: "No DMA's selected",
            xref: "paper",
            yref: "paper",
            showarrow: false,
            font: {
              size: 28,
              color: "red"
            }
        }
      ]
    },
    config: { displayModeBar: false },
    style: { width: "100%", height: "auto" },
    useResizeHandler: true,
  },
  _isCompact: false,
  _updateKPI: {}
};
