import React from "react";
import styled from "styled-components";

const Main = styled.div`
    width: fit-content;
    display: flex;
    justify-content: space-around;
    padding-top: 25px;
`

const LegendItem = styled.div`
  display:flex;
  align-items: center;
  color: #6B7D81;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  margin-right: 30px;
`

const LegendMarker = styled.span`
  height: 16px;
  width: 16px;
  border-radius: 8px;
  margin-right: 6px;
`

export default function SunburstLegend() {
    return (
        <Main>
            <LegendItem>
                <LegendMarker style={{backgroundColor:'#AFCE82'}}></LegendMarker>
                Asset properties without leaks
            </LegendItem>
            <LegendItem>
                <LegendMarker style={{backgroundColor:'#FF8E81'}}></LegendMarker>
                Asset properties with leaks
            </LegendItem>
        </Main>
    )
}