import {DMA} from "../model/DMA";
import { printCalculationError } from "../helper/helper";

//TODO: To be implemented after getting connection data sorted
export const getTotalConnections = (
  data: {}[],
  date: string,  
  dmaCodes: DMA[]
) => {  
 let sum: number = 0;

 data.forEach((object: any) => {
     let dma: any = Object.values(object)[0];

     dmaCodes.forEach((e) => {
       if (e.name === dma.polygon_description && e.selected === true) {
         let index: number = dma.connections.dates.indexOf(date);
         let value: number = parseFloat(dma.connections.values[index]);

       if (!isNaN(value)) {
         sum += value;
       } else {
         printCalculationError("Non Revenue Water per connection per day", date, value);
       }
     }});
     
 })

 return sum;
};