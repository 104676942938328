import {DMA} from "../model/DMA";


export const filterAssetSummaryData = (
data : {}[],
dmaCodes: DMA[]
) => {
    let filteredData : any[] = [];
    data.forEach((object: any) => {
       
        dmaCodes.forEach((e) => {
            if (object.polygon_code === e.code && e.selected === true) {
                filteredData.push(object);
            }
        });
      
    });
  return filteredData;
}

export const getTotalLeaks = (data: any, activeDMAs: DMA[]) => {
    let sum: number = 0;
    let dmas = activeDMAs.map(dma => dma.code);

    if (data === undefined) {
        return 0;
    }

    data.forEach((row: any) => {
        if (dmas.includes(row.polygon_code)) {
            sum += parseInt(row.fault_count);
        }
    })

    return sum;
}