import moment, { Moment } from 'moment';
import { colorScheme } from '../state/colors';
import { DMA } from '../model/DMA';
import { BarGraphView } from '../model/BarGraphProps';

export const getDate = (numberOfMonths: number, format: string) => {
    let date: string = moment().subtract(numberOfMonths, 'months').format(format);
    return format !== 'YYYY-MM' ?  date : date + '-01';
}

export const getDatesMapping = (numberOfMonths: number, format: string) => {
    return {
        month: getDate(numberOfMonths, format),
        lastMonth: getDate(numberOfMonths + 1, format),
        lastYear: getDate(numberOfMonths + 12, format)
    }
}

export const getDateHeader = () => {
    return moment().subtract(3, 'months');
}
export const getDateHeaderYearBack = () => {
    return moment(getDateHeader()).subtract(1, 'year');
}

export const formatDate = (date: Moment, formatString: string) => {
    return moment(date).format(formatString);
}


export const round = (value: number) => {
    let rounded: number = Math.round(value * 10) / 10;
    let oneDecimalPoint: string = rounded.toFixed(1);
    return oneDecimalPoint;
}

export const convertToMegaLitresPerDay = (sum: number, date: string) => {
    let daysInMonth: number = moment(date).daysInMonth();

    return sum / (1000 * daysInMonth);
};

export const getRange = (data: {}[], yearsToSubtract: number) => {
    let dates: any = Object.values(data[0])[0];
    let dateRange = [];
    dateRange.push(
      moment(dates.consumption.dates[dates.consumption.dates.length - 1])
        .subtract(yearsToSubtract, "year")
        .add(14, "days")
        .format("YYYY-MM-DD")
    );
    dateRange.push(
      moment(dates.consumption.dates[dates.consumption.dates.length - 1])
        .add(14, "days")
        .format("YYYY-MM-DD")
    );
    return dateRange;
  };
  
export const getActiveDMACount = (dmaCodes: DMA[]) => {
    let sum: number = 0;
    dmaCodes.forEach((dma: DMA) => {
        if (dma.selected === true) {
            sum += 1;
        }
    })

    return sum;
}

export const getActiveDMAs = (dmaCodes: DMA[]) => {
    return dmaCodes.filter((dma:DMA) => dma.selected === true);
}

export const getColorScheme = (numberOfDMAs: number) => {
    if (numberOfDMAs <= 5) {
        return colorScheme.five;
    } else if (numberOfDMAs <= 10) {
        return colorScheme.ten;
    } else if (numberOfDMAs <= 20) {
        return colorScheme.twenty;
    } else if (numberOfDMAs <= 30) {
        return colorScheme.thirty;
    } else {
        return colorScheme.sixty;
    }
}

export const hideDMALegends = (data: any) => {
    let numOfActiveDMAs = getActiveDMACount(data._dmaCodes);
    if (numOfActiveDMAs > 8) {
        data._nonRevenueWater.layout.showlegend = false;
        data._supply.layout.showlegend = false;
    } else {
        data._nonRevenueWater.layout.showlegend = true;
        data._supply.layout.showlegend = true;
    }
    return;
}

export const setFullScreenStyles = (data: BarGraphView) => {
    data.graphdata.style = { 
        width: "100%", 
        height: "100%" 
    }
    data.graphdata.layout.showlegend = true; 
    if (data.chartname !== "NonRevenueWater") {
        data.graphdata.layout.xaxis.range = setCurrentChartRanges(data.graphdata.layout.xaxis.range, 2);
    }
}

export const setDefaultChartRanges = (dashboardData: any, data: any) => {
    dashboardData._consumption.layout.xaxis.range = getRange(data, 1);
    dashboardData._supply.layout.xaxis.range = getRange(data, 1);
    dashboardData._nonRevenueWater.layout.xaxis.range = getRange(data, 2);
}

export const setCurrentChartRanges = (range: any, year: any) => {
     range[1] = moment(range[0]).add(year, "years").format("YYYY-MM-DD");
     range[0] = moment(range[0]).format("YYYY-MM-DD");
     return range;
}

export const printCalculationError = (dataType: string, date: string, value: number) => {
    console.error(dataType, "data value is", value, "for the date:", date);
}
