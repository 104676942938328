import React from "react";
import PercentageChange from "./PercentageChange";
import styled from "styled-components";
import * as helper from "../../helper/helper";
import { KPIProps, KPIObject, DatesMapping } from "../../model/KPIModel";
import "../../index.css";
import { DMA } from "../../model/DMA";
import { getVariance, getTotalNRWPercentage, getTotalNRWLitres, getNetIncreaseForConnections } from "../../DAO/kpiData";
import {  getTotalSupply } from "../../DAO/supplyData";
import { getTotalConsumption } from "../../DAO/consumptionData";
import { getTotalConnections } from "../../DAO/connectionsData";

const NUM_MONTHS_TO_GO_BACK: number = 3;

const Main = styled.div`
    display: flex;
    height: 156px;
    max-width: 285px;
    min-width: 200px;
    align-items: center;
    justify-content: space-between;
    font-family: 'Nunito Sans', sans-serif;
    flex-direction: column;
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 5px 0 rgba(33,61,88,0.4);
`

const CompactMain = styled.div`
    display: flex;
    width: 190px;
    height: 170px;
    align-items: center;
    justify-content: space-between;
    font-family: 'Nunito Sans', sans-serif;
    flex-direction: column;
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 5px 0 rgba(33,61,88,0.4);
`

const Bold = styled.div`
    font-weight: bold;
    padding-top: 12px;
    margin-bottom: 0;
`

const KPIDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    flex-direction: column;
    height: 100%;
`

const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    height: 70%;
`

const ValueColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
    margin-top: 12px;
`

const TrendColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
`

const Value = styled.div`
    color: #213D58;
    font-size: 32px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 43px;
`

const Unit = styled.span`
    color: #213D58;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 27px;
    margin-left: 6px;
`;


const NetIncrease = styled.div`
    color: #1C7B97;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 19px;
    text-align: center;
    margin-top: 4px;
`

const Subtitle = styled.div`
    color: #43575A;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 16px;
    text-align: center;
    max-width: 115px;
`

export const createKPI = (title: string, data: {}[], dmaCodes: DMA[]) => {
  let kpi: KPIObject;
  let format: string = "YYYY-MM";
  let dates: DatesMapping = helper.getDatesMapping(
    NUM_MONTHS_TO_GO_BACK,
    format
  );

  switch (title) {
    case "supply":
      kpi = getSupplyObject(data, dates, dmaCodes);
      break;
    case "consumption":
      kpi = getConsumptionObject(data, dates, dmaCodes);
      break;
    case "nonRevenueWaterPercent":
      kpi = getNRWPercentageObject(data, dates, dmaCodes);
      break;
    case "nonRevenueWaterAbsolute":
      kpi = getNRWLitresObject(data, dates, dmaCodes);
      break;
    default:
      kpi = getConnectionsObject(data, dates, dmaCodes);
  }

  kpi.monthVariance = getVariance(
    kpi.total,
    kpi.lastMonthTotal,
    title
  );
  kpi.yearVariance = getVariance(
    kpi.total,
    kpi.lastYearTotal,
    title
  );

  return kpi;
}

const getSupplyObject = (data: {}[], dates: DatesMapping, dmaCodes: DMA[]) => {
  return {
    unit: "ML/d",
    subtitle: '',
    total: getTotalSupply(data, dates.month, dmaCodes),
    lastMonthTotal: getTotalSupply(
      data,
      dates.lastMonth,
      dmaCodes
    ),
    lastYearTotal: getTotalSupply(data, dates.lastYear, dmaCodes),
    monthVariance: 0,
    yearVariance: 0,
  }
}

const getConsumptionObject = (data: {}[], dates: DatesMapping, dmaCodes: DMA[]) => {
  return {
    unit: 'ML/d',
    subtitle: '',
    total: getTotalConsumption(data, dates.month, dmaCodes),
    lastMonthTotal: getTotalConsumption(data, dates.lastMonth, dmaCodes),
    lastYearTotal: getTotalConsumption(data, dates.lastYear, dmaCodes),
    monthVariance: 0,
    yearVariance: 0,
  }
}

const getNRWPercentageObject = (data: {}[], dates: DatesMapping, dmaCodes: DMA[]) => {
  return {
    unit: '%',
    subtitle: 'of total supply',
    total: getTotalNRWPercentage(data, dates.month, dmaCodes),
    lastMonthTotal: getTotalNRWPercentage(data, dates.lastMonth, dmaCodes),
    lastYearTotal: getTotalNRWPercentage(data, dates.lastYear, dmaCodes),
    monthVariance: 0,
    yearVariance: 0
  }
}

const getNRWLitresObject = (data: {}[], dates: DatesMapping, dmaCodes: DMA[]) => {
    return {
        unit: 'L',
        subtitle: 'per connection per day',
        total: getTotalNRWLitres(data, dates.month, dmaCodes),
        lastMonthTotal: getTotalNRWLitres(data, dates.lastMonth, dmaCodes),
        lastYearTotal: getTotalNRWLitres(data, dates.lastYear, dmaCodes),
        monthVariance: 0,
        yearVariance: 0
    }
}

const getConnectionsObject = (data: {}[], dates: DatesMapping, dmaCodes: DMA[]) => {
  return {
      unit: '',
      subtitle: 'connections',
      total: getTotalConnections(data, dates.month, dmaCodes),
      lastMonthTotal: getTotalConnections(data, dates.lastMonth, dmaCodes),
      lastYearTotal: getTotalConnections(data, dates.lastYear, dmaCodes),
      netIncrease: getNetIncreaseForConnections(data, dates.month, dmaCodes),
      monthVariance: 0,
      yearVariance: 0
  }
}

const renderValue = (total: number, title: string, unit: string) => {
    let value: string;
    if (total && title !== 'Connections') {
        value = helper.round(total);
    } else if (total && title === 'Connections') {
        value = total.toLocaleString();
    } else {
        value = 'N/A';
    }

    return (
        <Value>
            {value}
            <Unit>{ unit }</Unit>
        </Value>
    )
}

const renderNetIncrease = (total:number) => {
    let value: string;
    
    value = total > 0 ? "+" + total : total.toString();
    return (
        <NetIncrease>
            ({value})
        </NetIncrease>
    )
}

const renderKPI = (title: string, data: KPIObject) => {
  return (
    <Main>
      <KPIDiv>
          <Bold>Total {title || 'N/A'}</Bold>
          <Container>
              <ValueColumn>
                  { renderValue(data.total, title, data.unit)}
                  { data.subtitle && <Subtitle>{data.subtitle.toUpperCase()}</Subtitle>}
                  { data.netIncrease && renderNetIncrease(data.netIncrease) }
              </ValueColumn>
              <TrendColumn>
              <PercentageChange value={helper.round(data.monthVariance) || 0} title="LAST MONTH" type={title}/>
              <PercentageChange value={helper.round(data.yearVariance) || 0} title="LAST YEAR" type={title}/>
          </TrendColumn>
          </Container>
      </KPIDiv>
    </Main>
  )
}

const renderCompactKPI = (title: string, data: KPIObject) => {
  return (
    <CompactMain>
      <KPIDiv>
          <Bold>Total {title || 'N/A'}</Bold>
          <ValueColumn style={{width:"100%"}}>
              { renderValue(data.total, title, data.unit)}
              <div style={{display:"flex", alignItems:"baseline", justifyContent:"space-evenly", width:"80%"}}>
              { data.subtitle && <Subtitle>{data.subtitle.toUpperCase()} </Subtitle>}
              { data.netIncrease && renderNetIncrease(data.netIncrease) }
              </div>

          </ValueColumn>
          <TrendColumn style={{width:"100%"}}>
            <PercentageChange value={helper.round(data.monthVariance) || 0} title="LAST MONTH" type={title} isCompact={true}/>
            <PercentageChange value={helper.round(data.yearVariance) || 0} title="LAST YEAR" type={title} isCompact={true}/>
          </TrendColumn>
      </KPIDiv>
    </CompactMain>
  )
}

export default function KPI({ title, data, isCompact }: KPIProps) {
  return isCompact ? renderCompactKPI(title, data) : renderKPI(title, data);
}
