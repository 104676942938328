import React from "react";
import { useState, useEffect } from "react";
import "./App.css";
import { initialDashboardData } from "./state/dashboardConfig";
import { useLocation } from "react-router-dom";
import GridContainer from "./components/Grid/GridContainer";
import GridItem from "./components/Grid/GridItem";
import BarGraph from "./components/BarGraph/BarGraph";
import KPI from "./components/KPI/KPI";
import Filter from "./components/Filter/Filter";
import SunBurstGraph, { renderEmptySunburstGraph } from "./components/SunburstGraph/SunburstGraph";
import SunburstLegend from "./components/SunburstGraph/Legend";
import styled from "styled-components";
import { getColorScheme, setFullScreenStyles, setDefaultChartRanges, getActiveDMACount, getActiveDMAs, setCurrentChartRanges } from "../src/helper/helper";
import { makeStyles, withStyles, Theme } from "@material-ui/core/styles";
import { chartType } from "./model/chartType";
import { BarGraphView } from "./model/BarGraphProps";
import { getDMADate } from "./DAO/dmaDates";
import { getDateHeader, getDateHeaderYearBack, formatDate, hideDMALegends } from "./helper/helper";
import { getTotalSupplyData } from "./DAO/supplyData";
import { getDMAs } from "./DAO/dmaData";
import { getConsumptionData } from "./DAO/consumptionData";
import { getNonRevenueWaterData } from "./DAO/nonRevenueWaterData";
import { createKPI } from "./components/KPI/KPI";
import { KPIObject } from "./model/KPIModel";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as CalendarIcon } from "./components/Icons/calendar-alt-regular.svg";
import { ReactComponent as InfoIcon } from "./components/Icons/info-circle-solid.svg";
import { ReactComponent as QuestionIcon } from "./components/Icons/question-circle-solid.svg";
import { HoverData } from "./model/BarGraphProps";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as ExpandIcon } from "./components/Icons/Icon_card-expand.svg";
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from "@material-ui/core";
import { filterAssetSummaryData } from "./DAO/assetSummary";
import Cookies from 'universal-cookie';
 
const cookies = new Cookies();
const DATE_FORMAT = "MMMM YYYY";

const Main = styled(GridContainer)`
  font-family: "Nunito Sans", sans-serif;
  flex-direction: column;
`;

const Header = styled.div`
  font-size: 32px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 43px;
  color: #213d58;
  margin-left: 30px;
  margin-top: 20px;
`;

const AssetHeader = styled.div`
  color: #213D58;
  font-family: "Nunito Sans";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
  margin-left: 30px;
  display: flex;
  align-items: center;
`;

const VerticalLine = styled.div`
  height: 10px;
  width: 3px;
  background-color: #b6bebf;
`;

const DateHeader = styled.div`
  color: #213d58;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  border-radius: 5px;
  background-color: #d0d5d5;
  padding: 6px 15px 5px 15px;
`;

const IconContainer = styled.div`
  path {
    fill: #8b9799;
  }
  min-width: 50px;
  display: flex;
  justify-content: space-between;
`;

const InfoTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    fontFamily: "Nunito Sans",
    backgroundColor: "black",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
  arrow: {
    color: "black",
  },
  tooltipPlacementRight: {
    padding: "12px",
  },
}))(Tooltip);

const NRWCard = styled.div`
  padding: 5px;
  margin-top: 25px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 1px 5px 0 rgba(33, 61, 88, 0.4);
  width: 99%;
`;

const AssetSummaryCard = styled.div`
  padding: 10px;
  margin-top: 25px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 1px 5px 0 rgba(33, 61, 88, 0.4);
  width: 99%;  
  display: block;
  padding-left: 10px;
`;

const ChartCard = styled.div`
  padding: 5px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 1px 5px 0 rgba(33, 61, 88, 0.4);
  height: auto;
`;

const AssetSummaryDate = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: #213D58;
  font-weight: bold;
  letter-spacing: 0;
  margin-right: 15px;
`

const StyledExpand = styled(ExpandIcon)`
  cursor: pointer;
  &:hover {
    border-radius: 40%;
    background-color: rgba(0, 0, 0, 0.04);
  }
`

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App() {
  const [dashboardData, setDashboardData] = useState(initialDashboardData);
  const [open, setOpen] = React.useState(false);
  const [summary, setSummary] = React.useState("DMA Summary");
  

  //Setting the theme breakpoints
  const theme = useTheme();
  theme.breakpoints.values.lg = 1600;
  dashboardData._isCompact = useMediaQuery(theme.breakpoints.down('md'));

  let query = useQuery();
  let url: string;
  let assetSummaryUrl: string;
  let data: any;
  let rawAssetSummaryData: any;
  
  const onToggle = (event: any)=> {
    var val = !event.currentTarget.checked ? "DMA Summary" : "Asset Summary";
    setSummary(val);
  }

  const handleClickOpen = (dmacharts: string) => {
    if (dmacharts === "TotalSupplyChart") {
      setDmachart(
        renderBarGraph({
          graphdata: dashboardData._supply,
          chartname: chartType[0].toString(),
          rawdata: dashboardData.rawData,
          graphname: chartType[0].toString(),
          fullscreen: true,
        })
      );
    } else if (dmacharts === "TotalConsumptionChart") {
      setDmachart(
        renderBarGraph({
          graphdata: dashboardData._consumption,
          chartname: chartType[1].toString(),
          rawdata: dashboardData.rawData,
          graphname: chartType[1].toString(),
          fullscreen: true,
        })
      );
    } else if (dmacharts === "AssetSummary") {
      setDmachart(renderAssetSummary(cookies.get("level"),cookies.get("size")));
    } else {
      setDmachart(
        renderBarGraph({
          graphdata: dashboardData._nonRevenueWater,
          chartname: chartType[2].toString(),
          rawdata: dashboardData.rawData,
          graphname: chartType[2].toString(),
          fullscreen: true,
        })
      );
    }

    setOpen(true);
  };

  const renderAssetSummary = (level: any, size: any) => {
    return (
      <div>
        <AssetHeader>
          <div>Asset Summary </div>
          <AssetSummaryDate>
            <span style={{ marginLeft: 20, marginRight: 20 }}> • </span>
            {formatDate(getDateHeaderYearBack(), DATE_FORMAT)} - {formatDate(getDateHeader(), DATE_FORMAT)}
          </AssetSummaryDate>
          <IconContainer>
            <div>
              <InfoTooltip
                title="You are looking at historical asset data for the last 12 months, from 2 months behind this month."
                placement="right"
                arrow
              >
                <InfoIcon style={{ width: "18px", height: "18px" }} />
              </InfoTooltip>
            </div>
          </IconContainer>
        </AssetHeader>
    
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: '20px 0px 0px 18px'
          }}
        >
          {
            (getActiveDMACount(dashboardData._dmaCodes) !== 0 && getActiveDMACount(dashboardData._dmaCodes) < 7)
            ? (
              <SunBurstGraph data={dashboardData._assetSummary} dmas={getActiveDMAs(dashboardData._dmaCodes)} level={level} size={size}/>
            )
            : renderEmptySunburstGraph()
          }
          <SunburstLegend />
        </div>
      </div>
    );
  };

  const handleClose = () => {
    setOpen(false);
    if(dashboardData.hasOwnProperty("rawData")){
      hideDMALegends(dashboardData);
      dashboardData._nonRevenueWater.layout.xaxis.range = setCurrentChartRanges(dashboardData._nonRevenueWater.layout.xaxis.range, 2);
      dashboardData._supply.layout.xaxis.range = setCurrentChartRanges(dashboardData._supply.layout.xaxis.range, 1);
      dashboardData._consumption.layout.xaxis.range = setCurrentChartRanges(dashboardData._consumption.layout.xaxis.range, 1);
    }
    
  };

  const useStyles = makeStyles((theme) => ({
    appBar: {
      position: "relative",
      boxShadow: "none",
    },
  }));

  const classes = useStyles();

  //Show Error
  const renderErrorBarGraph = (data: HoverData) => {
    return (
      <BarGraph
        data={[]}
        layout={dashboardData._error.layout}
        config={dashboardData._error.config}
        style={dashboardData._error.style}
        useResizeHandler={dashboardData._error.useResizeHandler}
        hoverName={""}
        hoverData={data}
      />
    );
  };

  const renderBarGraph = (data: BarGraphView) => {
    if (data.fullscreen) {
      setFullScreenStyles(data);
    }

    return (
      <BarGraph
        data={data.graphdata.data}
        layout={data.graphdata.layout}
        config={data.graphdata.config}
        style={data.graphdata.style}
        useResizeHandler={data.graphdata.useResizeHandler}
        hoverName={data.graphname}
        hoverData={data.rawdata}
      />
    );
  };

  const [dmachart, setDmachart] = React.useState(
    renderBarGraph({
      graphdata: dashboardData._supply,
      chartname: chartType[0].toString(),
      rawdata: dashboardData.rawData,
      graphname: "TotalSupplyChart",
      fullscreen: false,
    })
  );

  //Get Data from the API
  let fetchDashboardData = async () => {
    //Retrieve DMA codes from query parameters
    let dmaList = query.get("polygonCodes")?.split(",");

    if (dmaList !== undefined) {
      url = (process.env.REACT_APP_API_URL as string) + "dma/?polygonName=DMA&polygonCodes=" + dmaList.join();
      assetSummaryUrl = (process.env.REACT_APP_API_URL as string) + "assetSummary/?polygonCodes=" + dmaList.join();
    }

    //Get DMA Summary Data
    let res = await fetch(url);
    data = await res.json();

    //Get Asset Summary Data
    let resAssetSummary = await fetch(assetSummaryUrl);
    rawAssetSummaryData = await resAssetSummary.json();

    //Set the Asset Summary Data to Main Dashboard Object
    dashboardData._assetSummary = filterAssetSummaryData(rawAssetSummaryData, getDMAs(data));  

    //Get DMA
    dashboardData._dmaCodes = getDMAs(data);

    //Set the colour scheme
    dashboardData._nonRevenueWater.layout.colorway = getColorScheme(
      dashboardData._dmaCodes.length
    );
    dashboardData._consumption.layout.colorway = getColorScheme(
      dashboardData._dmaCodes.length
    );
    dashboardData._supply.layout.colorway = getColorScheme(
      dashboardData._dmaCodes.length
    );

    //Set the legend visibility on initializing
    if (dashboardData._dmaCodes.length > 8) {
      dashboardData._nonRevenueWater.layout.showlegend = false;
      dashboardData._supply.layout.showlegend = false;
    }

    //Set data
    dashboardData._consumption.data = getConsumptionData(
      getDMADate(data),
      data,
      getDMAs(data)
    );

    dashboardData._nonRevenueWater.data = getNonRevenueWaterData(data);
    dashboardData._supply.data = getTotalSupplyData(data);

    let _hoverData: HoverData = { rawData: data, dmaCodes: getDMAs(data) };
    dashboardData.rawData = _hoverData;

    //Set the range
    setDefaultChartRanges(dashboardData, data);

    //Processing KPI data
    Object.keys(initialDashboardData._kpis).forEach((kpiTitle: string) => {
      let kpi: KPIObject = createKPI(kpiTitle, data, getDMAs(data));
      initialDashboardData._kpis[kpiTitle] = kpi;
    });

    const updateKPI = () => {
      //Processing KPI data
      Object.keys(dashboardData._kpis).forEach((kpiTitle: string) => {
        let kpi: KPIObject = createKPI(kpiTitle, data, dashboardData._dmaCodes);
        dashboardData._kpis[kpiTitle] = kpi;
      });
    };

    dashboardData._updateKPI = updateKPI;

    const handleSelectDMA = (dmas: any) => {      
      dashboardData._dmaCodes = dmas;
      dashboardData.rawData.dmaCodes = dmas;
      dashboardData._assetSummary = filterAssetSummaryData(rawAssetSummaryData, dashboardData._dmaCodes);  
      dashboardData._consumption.data = getConsumptionData(
        getDMADate(data),
        data,
        dashboardData._dmaCodes
      );
      cookies.set('size', "small" , { path: '/' });
      cookies.set('level', "" , { path: '/' });
      cookies.set('clear', "true" , { path: '/' });
      hideDMALegends(dashboardData);
      setDashboardData({ ...dashboardData });
    };

    dashboardData._handleSelectDMA = handleSelectDMA;
    dashboardData._updateKPI();
    setDashboardData({ ...dashboardData });
    
  };

  useEffect(() => {
    cookies.set('size', "small" , { path: '/' });
    cookies.set('level', "" , { path: '/' });
    cookies.set('clear', "true" , { path: '/' });
    fetchDashboardData();    
  }, []);

  return (
    <div className="App" style={{ overflowX: "hidden", overflowY: "hidden"}}>
      <Main spacing={5}>
        <GridItem style={{ borderBottom: "1px solid #B6BEBF" }}>
          <Header
            style={{
              display: "flex",
              justifyContent: "",
              alignItems: "baseline",
            }}
          >
            <div>District Metered Areas</div>
            <DateHeader
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            >
              <CalendarIcon
                style={{
                  width: "17.5px",
                  height: "20px",
                  marginRight: "13px",
                }}
              />
              {formatDate(getDateHeader(), DATE_FORMAT)}
            </DateHeader>
            <IconContainer>
              <div>
                <InfoTooltip
                  title="Complete data is only available up to two months before today."
                  placement="right"
                  arrow
                >
                  <InfoIcon style={{ width: "18px", height: "18px" }} />
                </InfoTooltip>
              </div>
              <div>
                <InfoTooltip
                  title="View Data Summary"
                  placement="right"
                  arrow
                >
                  <a
                    style={{ display: "inline-block", position: "relative" }}
                    href={dashboardData.dmaSummaryConfluenceUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <QuestionIcon
                      style={{
                        width: "18px",
                        height: "18px",
                        cursor: "pointer",
                      }}
                    />
                  </a>
                </InfoTooltip>
              </div>
            </IconContainer>
            <div className="btn-group btn-group-toggle" style={{ position: 'absolute', float: 'right',right: '330px'}}>
          <p className={summary === "DMA Summary" ? "active labels" : "labels" } style={{ position: 'absolute', float: 'left', left: '34px', marginTop:'9px'}} >
            DMA Summary
          </p>
          <p className={summary === "Asset Summary" ? "active labels" : "labels" }  style={{ position: 'absolute', float: 'right',right: '-276px', marginTop:'9px'}} >
            Asset Summary
          </p>
          <label className="switch">
  <input id="checkboxinp" type="checkbox" onClick={onToggle}/>test
  <div className="slider round">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DMA Summary&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Asset Summary</div>
</label>
        </div>

          </Header>
        </GridItem>
        <GridItem>
          <GridContainer>
            <GridItem md={2} lg={2}>
              <GridContainer style={{height: "100%"}}>
                <Filter {...dashboardData} />
                <div
                  style={{
                    marginTop: "-17px",
                    height: "auto",
                    width: "1px",
                    backgroundColor: "#B6BEBF",
                  }}
                ></div>
              </GridContainer>
              <VerticalLine></VerticalLine>
            </GridItem>
            <GridItem style={{overflowY:"auto", height: "90vh", marginLeft: -5}} md={10} lg={10}>
              {summary === "DMA Summary" &&
              <GridContainer style={{ width: "100%" }}>
                <GridItem md={12} lg={12}>
                  <GridContainer
                    style={{ justifyContent: "space-evenly" }}
                  >
                    <GridItem md lg>
                      <KPI
                        title="NRW"
                        data={dashboardData._kpis.nonRevenueWaterAbsolute}
                        isCompact={dashboardData._isCompact}
                      />
                    </GridItem>
                    <GridItem md lg>
                      <KPI
                        title="NRW"
                        data={dashboardData._kpis.nonRevenueWaterPercent}
                        isCompact={dashboardData._isCompact}
                      />
                    </GridItem>
                    <GridItem md lg>
                      <KPI 
                        title="Supply" 
                        data={dashboardData._kpis.supply}
                        isCompact={dashboardData._isCompact}
                      />
                    </GridItem>
                    <GridItem md lg>
                      <KPI
                        title="Consumption"
                        data={dashboardData._kpis.consumption}
                        isCompact={dashboardData._isCompact}
                      />
                    </GridItem>
                    <GridItem md lg>
                      <KPI
                        title="Connections"
                        data={dashboardData._kpis.connections}
                        isCompact={dashboardData._isCompact}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              }
              <GridContainer style={{ width: "99%" }}>
                {summary === "DMA Summary" &&
                <GridItem md={12} lg={12}>
                  <NRWCard>
                    {dashboardData._dmaCodes.length > 0 ? (
                      <div>
                        <div
                          style={{
                            backgroundColor: "white",
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: "5px",
                          }}
                        >
                          <StyledExpand
                            onClick={() =>
                              handleClickOpen(chartType[2].toString())
                            }
                          />
                        </div>
                        {renderBarGraph({
                          graphdata: dashboardData._nonRevenueWater,
                          chartname: chartType[2].toString(),
                          rawdata: dashboardData.rawData,
                          graphname: chartType[2].toString(),
                          fullscreen: false,
                        })}
                      </div>
                    ) : (
                      renderErrorBarGraph(dashboardData.rawData)
                    )}
                  </NRWCard>
                </GridItem>
                }
                <GridItem md={12} lg={12} style={{ marginTop: "25px" }}>
                  {
                    <GridContainer spacing={4} style={{paddingBottom:'30px'}}>
                      {summary === "DMA Summary" &&
                      <>
                      <GridItem md={6} lg={6}>
                        <ChartCard>
                          {dashboardData._dmaCodes.length > 0 ? (
                            <div>
                              <div
                                style={{
                                  backgroundColor: "white",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  padding: "5px",
                                }}
                              >
                                <StyledExpand
                                  onClick={() =>
                                    handleClickOpen(chartType[0].toString())
                                  }
                                />
                              </div>
                              {renderBarGraph({
                                graphdata: dashboardData._supply,
                                chartname: chartType[0].toString(),
                                rawdata: dashboardData.rawData,
                                graphname: chartType[0].toString(),
                                fullscreen: false,
                              })}
                            </div>
                          ) : (
                            renderErrorBarGraph(dashboardData.rawData)
                          )}
                        </ChartCard>
                      </GridItem>
                      <GridItem md={6} lg={6}>
                        <ChartCard>
                          {dashboardData._dmaCodes.length > 0 ? (
                            <div>
                              <div
                                style={{
                                  backgroundColor: "white",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  padding: "5px",
                                }}
                              >
                                <StyledExpand
                                  onClick={() =>
                                    handleClickOpen(chartType[1].toString())
                                  }
                                />
                              </div>
                              {renderBarGraph({
                                graphdata: dashboardData._consumption,
                                chartname: chartType[1].toString(),
                                rawdata: dashboardData.rawData,
                                graphname: chartType[1].toString(),
                                fullscreen: false,
                              })}
                            </div>
                          ) : (
                            renderErrorBarGraph(dashboardData.rawData)
                          )}
                        </ChartCard>
                      </GridItem>
                      </>
                      }
                      {summary === "Asset Summary" && 
                      <GridItem md={12} lg={12} style={{width: '100%', marginTop: "-50px"}}>                       
                        <AssetSummaryCard>
                          <div
                            style={{
                              backgroundColor: "white",
                              display: "flex",
                              justifyContent: "flex-end",
                              padding: "5px",
                            }}
                          >
                            { getActiveDMACount(dashboardData._dmaCodes) !== 0 && getActiveDMACount(dashboardData._dmaCodes) < 7
                              && <ExpandIcon
                                onClick={() =>
                                  handleClickOpen(chartType[3].toString())
                                }
                              />
                            }
                          </div>
                          {renderAssetSummary(cookies.get("level"),cookies.get("size"))}
                        </AssetSummaryCard>
                      </GridItem>
                      }
                    </GridContainer>
                  }
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      </Main>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar className={classes.appBar} color="transparent">
          <Toolbar style={{justifyContent: "flex-end"}}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {dmachart}
      </Dialog>
    </div>
  );
}

export default App;
