import {DMA} from "../model/DMA";
import moment from "moment";
import { getTotalSupply } from "../DAO/supplyData";
import { printCalculationError, convertToMegaLitresPerDay } from "../helper/helper";
import { getTotalConnections} from "../DAO/connectionsData";
import { getTotalNRW } from "../DAO/nonRevenueWaterData";

//TODO: To be implemented after getting connection data sorted
export const getTotalNRWLitres = (
  data: {}[], 
  date: string, 
  dmaCodes: DMA[]
) => {

  let totalConnections: number = getTotalConnections(data, date, dmaCodes);
  let totalNRW: number = getTotalNRW(data, date, dmaCodes);

  return (totalNRW / totalConnections) * 1000000;
};

  
  export const getNetIncreaseForConnections = (
    data: {}[], 
    month: string, 
    dmaCodes: DMA[]
  ) => {
    let sum: number = 0;
    
    data.forEach(object => {
        let dma: any = Object.values(object)[0];
  
        dmaCodes.forEach((e) => {
          if (e.name === dma.polygon_description && e.selected === true) {
            let index: number = dma.connections.dates.indexOf(month);
            let currentConnection: number = parseInt(dma.connections.values[index]);
  
            if (index === 0) {
              sum += currentConnection;
            }
  
            let previousConnection: number = parseInt(dma.connections.values[index-1]);
            if (!isNaN(currentConnection) && !isNaN(previousConnection)) {
              sum += currentConnection - previousConnection;
            } else {
              printCalculationError("Total connections", month, currentConnection);
            }
  
          }});
       
    })
  
    return sum;
  }
  


  export const getVariance = (
    total: number,
    previousTotal: number,
    title: string
  ) => {
    if (title === "nonRevenueWaterPercent") {
      return total - previousTotal;
    } else {
      return ((total - previousTotal) / Math.abs(previousTotal)) * 100;
    }
  };

  export const getTotalNRWPercentage = (
    data: {}[],
    date: string,
    dmaCodes: DMA[]
  ) => {
    let sum: number = 0;
    let supply: number = getTotalSupply(data, date, dmaCodes);
  
    data.forEach(object => {
      let dma: any = Object.values(object)[0];
      dmaCodes.forEach((e) => {
        if (e.name === dma.polygon_description && e.selected === true) {
          let index: number = dma.non_revenue_water.dates.indexOf(date);
          sum += parseFloat(dma.non_revenue_water.values[index]);
        }
      });
    });
  
    return (convertToMegaLitresPerDay(sum, date) / supply) * 100;
  };


  