import {DMA} from "../model/DMA";
import {getTotalConnections } from "./connectionsData";
import { printCalculationError, convertToMegaLitresPerDay } from "../helper/helper";

export const getDomesticData = (
  data: {}[], 
  date: string, 
  dmaCodes: DMA[]
  ) => {
  let sum: number = 0;

  data.forEach(object => {      
      let dma: any = Object.values(object)[0];   
      dmaCodes.forEach((e) => {
        if (e.name === dma.polygon_description && e.selected === true) {        
          let index: number = dma.consumption.dates.indexOf(date);
          sum += parseFloat(dma.consumption.domestic[index]);
        }});    
      
  })    
  return convertToMegaLitresPerDay(sum, date);
}

export const getNonDomesticData = (
  data: {}[],
  date: string,
  dmaCodes: DMA[]
) => {
  let sum: number = 0;
  data.forEach((object) => {
    let dma: any = Object.values(object)[0];
    dmaCodes.forEach((e) => {
      if (e.name === dma.polygon_description && e.selected === true) {
        let index: number = dma.consumption.dates.indexOf(date);
        sum += parseFloat(dma.consumption.non_domestic[index]);
      }
    });
  });

  return convertToMegaLitresPerDay(sum, date);
};

export const getTotalConsumption = (
  data: {}[],
  date: string,
  dmaCodes: DMA[]
) => {
  let sum: number = 0;

  data.forEach((object) => {
    let dma: any = Object.values(object)[0];

    dmaCodes.forEach((e) => {
      if (e.name === dma.polygon_description && e.selected === true) {
        let index: number = dma.consumption.dates.indexOf(date);
        let domestic: number =  parseFloat(dma.consumption.domestic[index]);
        let nonDomestic: number = parseFloat(dma.consumption.non_domestic[index]);

        if (!isNaN(domestic)) {
          sum += domestic;
        } else {
          printCalculationError("Domestic Consumption", date, domestic);
        }

        if (!isNaN(nonDomestic)) {
          sum += nonDomestic;
        } else {
          printCalculationError("Non Domestic Consumption", date, nonDomestic);
        }
      }
    });
  });

  return convertToMegaLitresPerDay(sum, date);
};

export const getConsumptionData = (
  dmaDataDates: [],
  data: {}[],
  dmaCodes: DMA[]  
) => {
  let nonDomesticData: string[] = [];
  let domesticData: string[] = [];
  let connectionData: string[] = [];
  let sumNonDomestic: number = 0;
  let sumDomestic: number = 0;
  let sumConnection : number = 0;
  
  dmaDataDates.forEach((dmaDate: string) => {
    sumDomestic = getDomesticData(data, dmaDate, dmaCodes);
    sumNonDomestic = getNonDomesticData(data, dmaDate, dmaCodes);
    sumConnection = getTotalConnections(data, dmaDate, dmaCodes)
    nonDomesticData.push((Math.round(sumNonDomestic * 100) / 100).toString());
    domesticData.push((Math.round(sumDomestic * 100) / 100).toString());
    connectionData.push(sumConnection.toString());
  });
  
  return [
    {
      x: dmaDataDates,
      y: nonDomesticData,
      type: "bar",
      name: "Non Domestic",
      marker: {
        color: "#40B3DB",
      },
    },

    {
      x: dmaDataDates,
      y: domesticData,
      type: "bar",
      name: "Domestic",
      marker: {
        color: "#213D58",
      },
    },
    {
      x: dmaDataDates,
      y: connectionData,
      type: 'scatter',
      mode: 'lines+markers',
      name: 'Number of Connections',
      line: {shape: 'spline'},
      yaxis: 'y2' ,      
      marker: {
        color: '#F9C83A',
        size: 9
      }
    }     
  ];
};