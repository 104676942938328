import React from "react";
import Plot from "react-plotly.js";
import { BarGraphProps } from "../../model/BarGraphProps";
import { PointModel } from "../../model/PointModel";
import { hoverTemplate } from "./chartHover";


export default function BarGraph({
  data,
  layout,
  config,
  style,
  useResizeHandler,
  hoverName,
  hoverData
}: BarGraphProps) {  
  let filterData = data.filter((e: any) => {
    let isChecked = true;
    if (e.selected !== undefined) {
      isChecked = e.selected;
    }
    return isChecked;
  });

  //Sort the filtered data alphabetically
  filterData.sort(function(a: any, b: any) {
    let traceA = a.name.toUpperCase();
    let traceB = b.name.toUpperCase();
    return (traceA < traceB) ? -1 : (traceA > traceB) ? 1 : 0;
  })

  const onHoverHandler = (event: any) => {
    event.points.forEach((point: any) => {    
      let pointData : PointModel;               
      pointData = { name: point.data.name, type: point.fullData.type,  pointx : point.x, pointy: point.y }  
      point.fullData.hovertemplate = hoverTemplate(pointData, hoverData, hoverName);
    });
  }

  return (
    <Plot
      data={filterData}
      layout={layout}
      config={config}
      style={style}
      useResizeHandler={useResizeHandler}
      onHover={onHoverHandler}
    />
  );
}
