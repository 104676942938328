import { getTotalSupply } from "../../DAO/supplyData";
import { getDMAs } from "../../DAO/dmaData";
import {
  getDomesticData,
  getNonDomesticData,
  getTotalConsumption,
} from "../../DAO/consumptionData";
import { getNetIncreaseForConnections } from "../../DAO/kpiData";
import { chartType } from "../../model/chartType";
import { PointModel } from "../../model/PointModel";
import { HoverData } from "../../model/BarGraphProps";
import { getTotalNRW } from "../../DAO/nonRevenueWaterData";
import * as helper from "../../helper/helper";

const getConsumptionHover = (point: PointModel, hoverData: HoverData) => {
  let newData: any = [];

  hoverData.rawData.forEach((object : any) => {
    let dma: any = Object.values(object)[0];
    hoverData.dmaCodes.forEach((e : any) => {
      if (e.name === dma.polygon_description && e.selected === true) {
        newData.push(object);
      }
    });
  });
    
  let _totalConsumption = getTotalConsumption(newData, point.pointx, getDMAs(newData))
  let consumptionPercentage = Math.round((point.pointy / _totalConsumption) * 100)
  let netIncreaseForConnections = getNetIncreaseForConnections(newData, point.pointx, getDMAs(newData))

  if (point.type === "bar") {
    return (
      "<sub> %{x}</sub><br>" +
      " %{data.name}: <b>%{y:,} ML/d </b> <br>" +
      " <sup>" + Math.abs(consumptionPercentage) + "% of " + Math.round(_totalConsumption * 100) / 100 + " ML/d</sup><extra></extra>"
    );
  } else if (point.type === "scatter") {
    return (
      "<sub>  %{x}</sub> <br>" + 
      " Total Connections: <b>%{y:,} </b><br>" +
      " <sup>New Connections: " + netIncreaseForConnections + "</sup><extra></extra>"
    );
  }
};

export const getSupplyHover = (point: PointModel, hoverData: HoverData) => {

  let newData: any = [];

  hoverData.rawData.forEach((object : any) => {
    let dma: any = Object.values(object)[0];
    hoverData.dmaCodes.forEach((e : any) => {
      if (e.name === dma.polygon_description && e.selected === true) {
        newData.push(object);
      }
    });
  });

  let _totalSupply = getTotalSupply(newData, point.pointx, getDMAs(newData));
  let supplyPercentage: number = Math.round((point.pointy / _totalSupply) * 100);
  return (
    "<sub>  %{x}</sub><br>" +
    " %{data.name}: <b>%{y:,} ML/d </b> <br>" +
    " <sup> " + Math.abs(supplyPercentage) + "% of "+  Math.round(_totalSupply * 100 ) / 100 + " ML/d</sup><extra></extra>"
  );
};


export const getNRWHover = (point: PointModel, hoverData: HoverData) => {

  let newData: any = [];
  let selectedDma: {}[] = [];

  hoverData.rawData.forEach((object : any) => {
    let dma: any = Object.values(object)[0];
    
    hoverData.dmaCodes.forEach((e : any) => {
      if (e.name === dma.polygon_description && e.selected === true) {
        newData.push(object);
      }
    });

    if (dma.polygon_description === point.name) {
      selectedDma.push(object);
    }
  });

  let _totalNRW = getTotalNRW(newData, point.pointx, getDMAs(newData));
  let _totalSupply = getTotalSupply(selectedDma, point.pointx, getDMAs(newData));
  let supplyPercentage: number = Math.round((point.pointy / parseFloat(helper.round(_totalSupply))) * 100);
  return (
    "<sub> %{x}</sub><br>" +
    "%{data.name}: <b>%{y:,} ML/d</b><br>" +
    "<sub> " + Math.abs(supplyPercentage) + "% of Total DMA Supply</sub><br><br>" + 
    "<sup> Total NRW: " +  Math.round(_totalNRW * 100) /100 + " ML/d</sup><extra></extra>"
  );
};


export const hoverTemplate = (point: PointModel, hoverData: HoverData, name: string) => {  
  if (name === chartType[1].toString()) {
    return getConsumptionHover(point, hoverData);
  } else if (name === chartType[0].toString()) {    
    return getSupplyHover(point, hoverData);
  } else if (name === chartType[2].toString()) {    
    return getNRWHover(point, hoverData);
  }
};
