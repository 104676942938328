import { DMA } from "../model/DMA";
import { getTotalSupply } from "./supplyData";
import { printCalculationError, convertToMegaLitresPerDay, round } from "../helper/helper";
import {getTotalConnections} from "./connectionsData";

export const getTotalNRWPercentage = (
  data: {}[],
  date: string,
  dmaCodes: DMA[]
) => {
  let value: number = getTotalNRW(data, date, dmaCodes);
  let supply: number = getTotalSupply(data, date, dmaCodes);
  
  return (value / supply) * 100;
};


export const getNonRevenueWaterData = (data: any) => {
  let nonRevenueWaterData = data.map((dma: {}) => {
    let dmaData: any = Object.values(dma)[0];
    return {
      x: dmaData.non_revenue_water.dates,
      y: dmaData.non_revenue_water.values.map((num: number, index: number) => { 
        let dateTemp = dmaData.non_revenue_water.dates[index]; 
        return Math.round(convertToMegaLitresPerDay(num, dateTemp) * 100) / 100}),
      type: "bar",
      name: dmaData.polygon_description,
      selected: true,
    };
  });

  return nonRevenueWaterData;
};


export const getTotalNRW = (
  data: {}[],
  date: string,
  dmaCodes: DMA[]
) => {
  let sum: number = 0;

  data.forEach(object => {
    let dma: any = Object.values(object)[0];
    dmaCodes.forEach((e) => {
      if (e.name === dma.polygon_description && e.selected === true) {
        let index: number = dma.non_revenue_water.dates.indexOf(date);
        let value: number = parseFloat(dma.non_revenue_water.values[index]);

        if (!isNaN(value)) {
          sum += value;
        } else {
          printCalculationError("Non Revenue Water of total supply", date, value);
        }
      }
    });
  });

  return convertToMegaLitresPerDay(sum, date);
};


export const getTotalNRWLitres = (
  data: {}[], 
  date: string, 
  dmaCodes: DMA[]
) => {

  let totalConnections: number = getTotalConnections(data, date, dmaCodes);
  let totalNRW: number = getTotalNRW(data, date, dmaCodes);

  return (totalNRW / totalConnections) * 1000000;
};