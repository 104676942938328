import React from 'react';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import '../../index.css';
import { withStyles } from '@material-ui/core/styles';
import { getActiveDMACount } from '../../helper/helper';

const FilterDiv = styled.div`
  font-family: 'Nunito Sans', sans serif;
  color: #0A0D0D;
  padding-right: 43px;
  margin-left: 8%;
  min-width: 205px;
  max-height: 85vh;
`

const FilterSubtitle = styled.div`
  color: #0A0D0D;
  font-family: "Nunito Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
`

const FilterList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 82%;
  overflow-y: auto;
  margin: 20px 0;
`;

const NoDMAs = styled.div`
  color: red;
`;

const SelectionClear = styled.div`
  color: #1C7B97;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  width: fit-content;
  p:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const checkBoxStyles = (theme: any) => ({
  root: {
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      position: "absolute",
      backgroundColor: "white",
      borderRadius: 5,
      height: '24px',
      width: '24px',
      border: '1px solid #B6BEBF'
    },
    "&$checked": {
      color: '#40B3DB',
      height: '24px',
      width: '24px'
    }
  },
  checked: {}
})

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const renderSubtitle = (activeDMAs: number, numberOfDMAs: number) => {
  return (
    <FilterSubtitle>
      Showing <b>
        {activeDMAs} 
        {activeDMAs > 1 
          ? " DMAs"
          : " DMA"
        }
        </b> out of {numberOfDMAs}
    </FilterSubtitle>
  )
}

export default function Filter({ ...TPWDashboardProps }) {
  const handleClick = (event: any) => {
    TPWDashboardProps._dmaCodes.forEach((dma: any) => {
      if (dma.name === event.target.name) {
        dma.selected = event.target.checked;
      }
    });

    TPWDashboardProps._nonRevenueWater.data.forEach((e: any) => {
      if (e.name === event.target.name) {
        e.selected = event.target.checked;
      }
    });

    TPWDashboardProps._supply.data.forEach((e: any) => {
      if (e.name === event.target.name) {
        e.selected = event.target.checked;
      }
    });

    TPWDashboardProps._updateKPI();
    TPWDashboardProps._handleSelectDMA(TPWDashboardProps._dmaCodes);
  };

  const clearSelection = () => {
    TPWDashboardProps._dmaCodes.forEach((dma: any) => {
      dma.selected = false;
    });

    TPWDashboardProps._nonRevenueWater.data.forEach((e: any) => {
      e.selected = false;
    });

    TPWDashboardProps._supply.data.forEach((e: any) => {
      e.selected = false;
    });

    TPWDashboardProps._updateKPI();
    TPWDashboardProps._handleSelectDMA(TPWDashboardProps._dmaCodes);
  }

  //Sorting the DMA Codes into alphabetical order
  TPWDashboardProps._dmaCodes.sort(function(a:any, b: any) {
    return a.name.localeCompare(b.name)
  });

  const dmaList = TPWDashboardProps._dmaCodes.map((dma: any, index: number) => (
    <label className = "check-label" key={"label-" + index}>
      <CustomCheckbox
        className="dma-checkbox"
        checked={dma.selected}
        name={dma.name}
        color="primary"
        onClick={handleClick}
      />{" "}
      <span>{dma.name}</span>
    </label>
  ));

  let numberOfActiveDMAs: number = getActiveDMACount(TPWDashboardProps._dmaCodes);

  return (
    <FilterDiv>
        <FilterSubtitle style={{fontSize:'16px'}}>
          { renderSubtitle(numberOfActiveDMAs, TPWDashboardProps._dmaCodes.length) }
        </FilterSubtitle>
        <FilterList>
            {TPWDashboardProps._dmaCodes.length > 0 ? dmaList : <NoDMAs>No DMAs available.</NoDMAs>}
        </FilterList>            
        <SelectionClear><p onClick={clearSelection}>Clear Selection</p></SelectionClear>
    </FilterDiv>
  );
}
