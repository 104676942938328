import {DMA} from "../model/DMA";

export const getDMAs = (data: {}[]) => {
    let dmaList: any = [];
    data.forEach((dma, i) => {
      let _dma: DMA;
      let _dmaName: any = Object.values(data[i])[0];
      _dma = {
        code: Object.keys(data[i]).toString(),
        name: _dmaName.polygon_description,
        selected: true,
      };
      dmaList.push(_dma);
    });
  
    return dmaList;
  };