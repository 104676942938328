import React, { useState, useEffect } from "react";
import createPlotlyComponent from 'react-plotly.js/factory';
import animate from './factory';
import { getDateHeaderYearBack, getDateHeader, formatDate } from "../../helper/helper";
import Cookies from 'universal-cookie';
import SunburstDescription from "./Description";

const cookies = new Cookies();
const DATE_FORMAT = "MMM 'YY";
const Plot = createPlotlyComponent(window.Plotly);
const plotWrapper=(prop: any)=>{
 return (
  <Plot data={prop.data} layout={prop.layout}  onClick={prop.onClick} />
  )
}

const Memo = React.memo(plotWrapper, (prevProps, nextProps) => {
       return  (prevProps.data[0].ids.length === nextProps.data[0].ids.length && cookies.get('level') !== "");
      }
  
);

export default function SunBurstGraph(props : any ) {
    let data : any[] = props.data;
    const [path, setPath] = useState([]);
    if(data === undefined) {
      data = [];
    }
    let thisPath = path;
    if(cookies.get('clear') === "true" || cookies.get('level') === "root" ){
      thisPath = [];
    } else {
      thisPath = cookies.get('level').split("/");
    }
    let date = formatDate(getDateHeaderYearBack(), DATE_FORMAT) + " - " + formatDate(getDateHeader(), DATE_FORMAT);
    let transitionLevel =3;
    let ids: string[] = ["root"];
    let labels: string[] = [""];
    let initLabels: string[] = [""];
    let text: string[] = [""];
    let initText: string[] = [""];
    let hoverInfo: string[] = [""];
    let colors: string[] = ["white"];
    let values: number[] = [0];
    let initValues: number[] = [0];
    let initColor: string[] = ["white"];
    let parents: string[] = [""];
    var total = 0;
    var totalValue = 0;
    var initTotal= 0;
    // Format the number
    const numberFormat = (num: any) => {return num.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");}

    //Sort data tier by tier for sunburst
    let tier1 = data.filter( (ele, ind) => ind === data.findIndex( elem=> elem.polygon_description === ele.polygon_description));
    const handleBreadCrumb = (level: any, path: any) => {
      let plots = document.getElementsByClassName("js-plotly-plot");
      if(level === "") {
        cookies.set('clear', "true" , { path: '/' });
        cookies.set('level', ids[0] , { path: '/' });
        cookies.set('size', "small" , { path: '/' });
        animate(window.Plotly,plots[plots.length-1],ids,initLabels,initText,parents,initValues,initColor,2,626,hoverInfo,ids[0]);
        if(plots.length === 2 ){
          animate(window.Plotly,plots[plots.length-2],ids,initLabels,initText,parents,initValues,initColor,2,626,hoverInfo,ids[0]);
        }
        setPath([]);
      } else {
        const index = path.findIndex( (elem: any) => elem === level);
        let currentId ="";
        let thisLevel: any = [];
        for(let i = 0; i<= index - 1; i++ ){
          currentId = "/" + path[i] + currentId;
          thisLevel.push(path[i]);
        }
        thisLevel.push(level);
        currentId = level + currentId;
        let size = 626;
        if( currentId.split("/").length - 2 > -1){ 
          size = 768;
          cookies.set('size', "large" , { path: '/' });
        } else {
          cookies.set('size', "small" , { path: '/' });
        }
        cookies.set('level', currentId , { path: '/' });
        
        if(size === 768){
          animate(window.Plotly,plots[plots.length-1],ids,labels,text,parents,values,colors,6,size,hoverInfo,currentId); 
        }else {
          animate(window.Plotly,plots[plots.length-1],ids,initLabels,initText,parents,initValues,initColor,2,size,hoverInfo,currentId);
        }
        if(plots.length === 2 ){
          if(size === 768){
            animate(window.Plotly,plots[plots.length-2],ids,labels,text,parents,values,colors,6,size,hoverInfo,currentId); 
          }else {
            animate(window.Plotly,plots[plots.length-2],ids,initLabels,initText,parents,initValues,initColor,2,size,hoverInfo,currentId);
          }          
        }
        setPath(thisLevel); 
      }
      
    };
    data.map(item1 => { 
      total += item1.fault_count*1;
    });
    tier1.map(item1 => { // item
      let tier2 = data.filter(item => item.polygon_description === item1.polygon_description );
      ids.push(item1.polygon_description);
  
      parents.push("root");    
      let faultCount1 = tier2[0].fault_count*1;
      let value1 = tier2[0].value*1;
      tier2.reduce((arr2,tier2) => {
        value1 += tier2.value*1;   
        faultCount1 += tier2.fault_count*1;     
        return arr2;
      });
      totalValue+=value1;
      labels.push(item1.polygon_description);
      initLabels.push(item1.polygon_description);
      let faultCountStr1 = numberFormat(faultCount1);
      if(faultCount1>0){
        text.push("<br>" + faultCountStr1 + "<br>" + (faultCount1 === 1 ? " leak":" leaks") );
        initText.push("<br>" + faultCountStr1 + "<br>" + (faultCount1 === 1 ? " leak":" leaks") );
        colors.push("#213D58");
        values.push(value1);
      }else{
        text.push("<br>0<br>leaks");
        initText.push("<br>0<br>leaks");
        colors.push("#213D58");
        values.push(value1);
      }
      hoverInfo.push(item1.polygon_description + " <b>·</b> " + date + "<br><b>"+faultCountStr1+" " + (faultCount1 === 1 ? "leak":"leaks") + "</b> (out of "+ numberFormat(total) +" leaks)");
      let tier2Ids = tier2.filter( (ele, ind) => ind === tier2.findIndex( elem => elem.asset_category === ele.asset_category));
      initValues.push(4);
      initTotal+=4;
      tier2Ids.map(item2 => {
        let tier3 = tier2.filter(item => item.asset_category === item2.asset_category );
        ids.push(item2.asset_category + "/" + item1.polygon_description);
        let tierValue = 4/tier2Ids.length;
        initValues.push(tierValue);
        parents.push(item2.polygon_description);    
        let faultCount2 = tier3[0].fault_count*1;
        let value2 = tier3[0].value*1;
        tier3.reduce((arr3,tier3) => {
          value2 += tier3.value*1;   
          faultCount2 += tier3.fault_count*1;     
          return arr3;
        });
        let faultCountStr2 = numberFormat(faultCount2);
        let valueStr2 = numberFormat(Math.round(value2));
        let meter2 = item2.asset_category.indexOf("Meters") !== -1 ? "</b><br>No. of meters: " + valueStr2 : "</b><br>Pipe length: " + valueStr2 + "m";
        // Set Hoverinfo
        hoverInfo.push(item1.polygon_description + " <b>·</b> " + date + "<br><b>" + faultCountStr2 +
        (faultCount2 === 1 ? " leak":" leaks") + "</b> (out of "+ faultCountStr1 +" leaks)" +
          "<br>Attributes: <b>" +item2.asset_category + meter2);
        
        initLabels.push(item2.asset_category);
        labels.push(item1.polygon_description + "<br>_____<br>" + item2.asset_category);
        if(faultCount2>0){
          text.push("<br>" + faultCountStr2  + " leaks<br><-");
          initText.push("<br>" + faultCountStr2 );
          colors.push("#213D58");
          values.push(value2);
        }else{
          text.push("<br>0 leaks<br><-");
          initText.push("<br>0");
          colors.push("#213D58");
          values.push(value2);
        }
        let tier3Ids = tier3.filter( (ele, ind) => ind === tier3.findIndex( elem => elem.asset_type === ele.asset_type));
        tier3Ids.map(item3 => {
          let tier4 = tier3.filter(item => item.asset_type === item3.asset_type );
          ids.push(item3.asset_type + "/" + item2.asset_category + "/" + item1.polygon_description);
          initValues.push(tierValue/tier3Ids.length);
          parents.push(item3.asset_category + "/" + item3.polygon_description);    
          let faultCount3 = tier4[0].fault_count*1;
          let value3 = tier4[0].value*1;
          tier4.reduce((arr4,tier4) => {
            value3 += tier4.value*1;   
            faultCount3 += tier4.fault_count*1;     
            return arr4;
          });
          let faultCountStr3 = numberFormat(faultCount3);
          let valueStr3 = numberFormat(Math.round(value3));
          let meter3 = item3.asset_category.indexOf("Meters") !== -1 ? "</b><br>No. of meters: " + valueStr3 : "</b><br>Pipe length: " + valueStr3 + "m";
          hoverInfo.push(item1.polygon_description + " <b>·</b> " + date + "<br><b>"+ faultCountStr3 +
          (faultCount3 === 1 ? " leak":" leaks") + "</b> (out of "+ faultCountStr1 +" leaks)" +
          "<br>Attributes: <b>" +item3.asset_category + ", " +item3.asset_type + meter3);
          labels.push(item3.asset_type );
          initLabels.push("");
          initText.push("");
          if(faultCount3>0){
            text.push("");
            colors.push("#8D2C1B");
            values.push(value3);
          }else{
            text.push("");
            colors.push("#477A20");
            values.push(value3);
          }
          let tier4Ids = tier4.filter( (ele, ind) => ind === tier4.findIndex( elem => elem.age_category=== ele.age_category));
          tier4Ids.map(item4 => {
            let tier5 = tier4.filter(item => item.age_category === item4.age_category );
            ids.push(item4.age_category + "/" + item3.asset_type + "/" + item4.asset_category + "/" + item1.polygon_description);
            initValues.push(0);
            parents.push(item4.asset_type + "/" + item4.asset_category + "/" + item4.polygon_description);    
            let faultCount4 = tier5[0].fault_count*1;
            let value4 = tier5[0].value*1;
            tier5.reduce((arr5,tier5) => {
              value4 += tier5.value*1;   
              faultCount4 += tier5.fault_count*1;     
              return arr5;
            });
            let faultCountStr4 = numberFormat(faultCount4);
            let valueStr4 = numberFormat(Math.round(value4));
            let meter4 = item4.asset_category.indexOf("Meters") !== -1 ? "</b><br>No. of meters: " + valueStr4 : "</b><br>Pipe length: " + valueStr4 + "m";
            hoverInfo.push(item1.polygon_description + " <b>·</b> " + date + "<br><b>"+ faultCountStr4 +
            (faultCount4 === 1 ? " leak":" leaks") + "</b> (out of "+ faultCountStr1 +" leaks)" +
              "<br>Attributes: <b>" +item4.asset_category + ", " +item4.asset_type+ ", " + item4.age_category + meter4);
            labels.push(item4.age_category);
            initLabels.push("");
            initText.push("");
            if(faultCount4>0){
              text.push("");
              colors.push("#C35744");
              values.push(value4);
            }else{
              text.push("");
              colors.push("#AFCE82");
              values.push(value4);
            }
            let tier5Ids = tier5.filter( (ele, ind) => ind === tier5.findIndex( elem => elem.nom_dia_category === ele.nom_dia_category));
            tier5Ids.map(item5 => {
              let tier6 = tier5.filter(item => item.nom_dia_category === item5.nom_dia_category );
              ids.push(item5.nom_dia_category + "/" + item5.age_category + "/" + item5.asset_type + "/" + item5.asset_category + "/" + item5.polygon_description);
              initValues.push(0);
              parents.push(item5.age_category + "/" + item5.asset_type + "/" + item5.asset_category + "/" + item5.polygon_description);    
              let faultCount5 = tier6[0].fault_count*1;
              let value5 = tier6[0].value*1;
              tier6.reduce((arr6,tier6) => {
                value5 += tier6.value*1;   
                faultCount5 += tier6.fault_count*1;     
                return arr6;
              });
              let faultCountStr5 = numberFormat(faultCount5);
              let valueStr5 = numberFormat(Math.round(value5));
              let meter5 = item2.asset_category.indexOf("Meters") !== -1 ? "</b><br>No. of meters: " + valueStr5 : "</b><br>Pipe length: " + valueStr5 + "m";
              hoverInfo.push(item1.polygon_description + " <b>·</b> " + date + "<br>Attributes: <b>" +item5.asset_category + ", " +item5.asset_type + ", " + item5.age_category + ", " + item5.nom_dia_category 
              + "</b><br><b>"+ faultCountStr5 + (faultCount5 === 1 ? " leak":" leaks") + "</b> (out of "+ faultCountStr1 +" leaks)" + meter5);
              if(faultCount5>0){
                labels.push(item5.nom_dia_category);
                initLabels.push("");
                initText.push("");
                text.push("");
                colors.push("#CD6D5C");
                values.push(value5);
                ids.push("Faults"+item5.nom_dia_category + "/" + item5.age_category + "/" + item5.asset_type + "/" + item5.asset_category + "/" + item5.polygon_description);
                initValues.push(0);
                parents.push(item5.nom_dia_category + "/" + item5.age_category + "/" + item5.asset_type + "/" + item5.asset_category + "/" + item5.polygon_description);    
                colors.push("#FF8E81");
                hoverInfo.push(item1.polygon_description + " <b>·</b> " + date + "<br><b>"+ faultCountStr5 +
                (faultCount5 === 1 ? " leak":" leaks") + "</b> (out of "+ faultCountStr1 +" leaks)" +
                "<br>Attributes: <b>" +item5.asset_category + ", " +item5.asset_type + ", " + item5.age_category + ", " + item5.nom_dia_category + "</b>");
                values.push(value5);
                labels.push(faultCountStr5 + (faultCount5 === 1 ? " leak":" leaks"));
                initLabels.push("");
                initText.push("");
                text.push("");
              }else{
                labels.push(item5.nom_dia_category);
                initLabels.push("");
                initText.push("");
                text.push("");
                colors.push("#CCE8A4");
                values.push(value5);
              }
            }); 
          }); 
        });
       });

    });
    text[0]=numberFormat(tier1.length) + " DMAS<br>"+numberFormat(total)+"<br>Total leaks";
    initText[0]=numberFormat(tier1.length) + " DMAS<br>"+numberFormat(total)+"<br>Total leaks";
    hoverInfo[0]=numberFormat(tier1.length) + " DMAS <b>·</b> " + date + "<br><b>"+numberFormat(total)+"<b><br>Total leaks";
    values[0]=totalValue;
    initValues[0]=initTotal;
    for(let i = 1;i< colors.length; i++){
      initColor.push("#213D58");
    }
    //Don't display root when only have 1 DMA
    if(tier1.length === 1){
      ids.splice(0,1);
      labels.splice(0,1);
      initLabels.splice(0,1);
      initValues.splice(0,1);
      values.splice(0,1);
      parents.splice(0,1);
      text.splice(0,1);
      initText.splice(0,1);
      hoverInfo.splice(0,1);
      initColor.splice(0,1);
      initColor[0] = "white";
      colors.splice(0,1);
      parents[0] = "";
      transitionLevel = 2;
    }
    //Data and config of sunburst
    var datas : any = [{
        type: "sunburst",
        ids: ids,
        labels: initLabels,
        parents: parents,
        text: initText,
        hoverinfo: "text",
        insidetextfont: {family: "Nunito Sans", size: 15},
        hovertext: hoverInfo,
        hoverlabel: {font: {size: 14, family: "Nunito Sans"}, align: "left"},
        anim: true,
        level: props.level,
        values: initValues,
        outsidetextfont: {size: 20, color: "#377eb8"},
        leaf: {opacity: 1},
        marker: {line: {width: 1},"colors": initColor},
        maxdepth: 2,
        name:"",
        branchvalues: 'total',
        textposition: 'inside'
      }];
      
      var layout: any= {
        //uniformtext:{minsize:10, mode:'hide'},
        margin: {l: 0, r: 0, b: 0, t: 0},
        width: 626,
        height: 626
      };

      if( props.size === "large" ) {
        datas = [{
          type: "sunburst",
          ids: ids,
          labels: labels,
          text:text,
          parents: parents,
          anim: true,
          level: props.level,
          hoverinfo: "text",
          hovertext: hoverInfo,
          values:  values,
          outsidetextfont: {size: 20, color: "#377eb8"},
          leaf: {opacity: 1},
          marker: {line: {width: 1},"colors": colors},
          maxdepth: 6,
          branchvalues: 'total',
          textposition: 'inside'
        }];
        layout = {
          //uniformtext:{minsize:10, mode:'hide'},
          margin: {l: 0, r: 0, b: 0, t: 0},
          width: 786,
          height: 786
        };
      }
      return (
        <>
        <SunburstDescription data={data} dmas={props.dmas} path={thisPath} onClick={handleBreadCrumb}/> 
        <Memo data={datas} layout={layout}  onDoubleClick ={(e: any) => {e.event.preventDefault(); return false;}} onClick={(e: any)=>         
          { 
            let index = ids.findIndex( elem => elem === e.points[0].id);
            if(e.points[0].id !== "root" && index > 0 && parents.findIndex( elem => elem === e.points[0].id) !== -1) { 
              cookies.set('clear', "false" , { path: '/' });
              let plots = document.getElementsByClassName("js-plotly-plot");
              let currentPath = e.points[0].currentPath;            
              let currentRoot = e.points[0].entry === e.points[0].label ? parents[index] : e.points[0].id; 
              cookies.set('level', currentRoot , { path: '/' });
              let thisLevel = currentRoot.split("/");
              if(transitionLevel === 2) {
                thisLevel.pop();
              }
              if( thisLevel[0] === "root" ){
                thisLevel.pop();
              }
              if(currentPath!==undefined){
                let path = currentPath.split("/");
                if(e.points[0].entry === e.points[0].label && path.length === transitionLevel && e.points[0].id !== ids[0]){
                  cookies.set('size', "small" , { path: '/' });
                  animate(window.Plotly,e.event.path[7],ids,initLabels,initText,parents,initValues,initColor,2,626,hoverInfo); 
        
                }
                if(e.points[0].entry !== e.points[0].label && path.length === transitionLevel  && e.points[0].entry !== ""){
                  cookies.set('size', "large" , { path: '/' });
                  animate(window.Plotly,e.event.path[7],ids,labels,text,parents,values,colors,6,786,hoverInfo);   
                
              }
            } else {
              e.event.preventDefault(); return false
            }
            let size = cookies.get('size') === "small" ? 626 : 768;
            if(plots.length === 2 ) {
              if(size === 626){
                animate(window.Plotly,plots[plots.length-2],ids,initLabels,initText,parents,initValues,initColor,2,size,hoverInfo,currentRoot);
              } else { 
                animate(window.Plotly,plots[plots.length-2],ids,labels,text,parents,values,colors,6,size,hoverInfo,currentRoot);
              }
              
            }  
            setPath(thisLevel);
            }         
        }
      } />
      </>
   );
 }

export const renderEmptySunburstGraph = () => {
  let graphData: any = {
    type: "sunburst",
    labels: ["For an optimum experience <br>please select 6 DMAs or less", " "],
    parents: ["", "For an optimum experience <br>please select 6 DMAs or less" ],
    outsidetextfont: {family: "Nunito Sans", size: 20},
    values: [1, 1],
    leaf: {"opacity": 0.4},
      marker: {"line": {"width": 2}, "colors": ["","#9EDAE2"]},
      "branchvalues": 'total'
  }
  return (
    <Plot 
      data={[graphData]}
      layout={{
        margin: {
          l: 0, 
          r: 0, 
          b: 0, 
          t: 0
        },
        width: 626,
        height: 626,
        hovermode: false,
        images: [
          {
            x: 0.56,
            y: 0.55,
            sizex: 0.12,
            sizey: 0.12,
            source: "https://watercare.invisionapp.com/assets/A_MGFjZjlkZDY2YjhlM2JmOcaIH-w1Hk0t8Twl6ZJ4Bj8fyLDD9FGsYaN2bQ2weMRG5qno75M4iu-TxSrnZBoE8sVTk-9FbXMaje2X_zFiD1xzYOURAqUvZCDnW3_XNL8y?height=144&fit=scale-down",
            xanchor: "right",
            xref: "paper",
            yanchor: "bottom",
            yref: "paper"
          }
        ],
      }}
      config={{ displayModeBar: false }}
    />
  )
}